export class Countdown {

    /**
     * ```html
     * <canvas id="days" width="80" height="80"></canvas>
     * <canvas id="hours" width="80" height="80"></canvas>
     * <canvas id="minutes" width="80" height="80"></canvas>
     * <canvas id="seconds" width="80" height="80"></canvas>
     * ```
     * @param {Date} dateTo Data do której ma być odliczanie
     */
    constructor(dateTo) {

        let $ = el => document.querySelector(el);
        this.dateSelectors = {
            d: $('.days').getContext('2d'),
            h: $('.hours').getContext('2d'),
            m: $('.minutes').getContext('2d'),
            s: $('.seconds').getContext('2d')
        };
        this.mqSettings={
            mobile:{
                font: 'bold 1.2rem sans-serif',
                cavnasYPosition: 20,
                cavnasXPosition: 18,
                cardHeight: '55px',
                cardWidth: '45px'
            },
            tablet:{
                font: 'bold 1.5rem sans-serif',
                cavnasYPosition: 25,
                cavnasXPosition: 20,
                cardHeight: '60px',
                cardWidth: '50px'
            },
            screen:{
                font: 'bold 2rem sans-serif',
                cavnasYPosition: 35,
                cavnasXPosition: 20
            }
        };
        this.dateTo = dateTo;
        requestAnimationFrame(() => this.set());

        this.font = this.mqSettings.screen.font;
        this.cavnasYPosition = this.mqSettings.screen.cavnasYPosition;
        this.cavnasXPosition = this.mqSettings.screen.cavnasXPosition;
        let mobileViewport = window.matchMedia("screen and (max-width:40em)");
        let tabletViewport = window.matchMedia("(min-width:41em) and (max-width:50em)");
        let screenViewport = window.matchMedia("(min-width:51em)");
        this.checkBrowserSize(mobileViewport, this.mqSettings.mobile);
        this.checkBrowserSize(tabletViewport, this.mqSettings.tablet);
        this.checkBrowserSize(screenViewport, this.mqSettings.screen);
        mobileViewport.addListener((mq)=>this.checkBrowserSize(mq, this.mqSettings.mobile));
        tabletViewport.addListener((mq)=>this.checkBrowserSize(mq, this.mqSettings.tablet));
        screenViewport.addListener((mq)=>this.checkBrowserSize(mq, this.mqSettings.screen));
            this.listeners = [];
        }

        checkBrowserSize(mq, settings){
            if (mq.matches) {
                // viewport <= 40em
                this.font = settings.font;
                this.setCardSize(settings.cardHeight, settings.cardWidth);
                this.cavnasYPosition = settings.cavnasYPosition;
                this.cavnasXPosition = settings.cavnasXPosition;
            // } else {
            //     // viewport > 40em
            //     this.font = this.mqSettings.screen.font;
            //     this.setCardSize();
            //     this.cavnasYPosition = this.mqSettings.screen.cavnasYPosition;
            //     this.cavnasXPosition = this.mqSettings.screen.cavnasXPosition;
            }
        }

        /**
         * Ustawia wysokość canvasa
         */
        setCardSize(height='', width=''){
            document.querySelectorAll('.card').forEach((i)=>{
                i.style.maxHeight=height;
                i.style.maxWidth=width
            })
        }

        onEnd(callback) {
            this.listeners.push(callback);
        }

        /**
         * Główna funkcja
         */
        set() {

            let today = new Date();

            //sprawdzam różnice w datach
            if (this.dateTo.getTime() - today.getTime() <= 0) {
                this.listeners[0]();
                //return false;
            } else {
                for (let selector in this.dateSelectors) {
                    let s = this.dateSelectors[selector];
                    this.clear(s);
                    this.setTime(s, this.dateDiff(selector, today, this.dateTo));
                }
                requestAnimationFrame(() => this.set());
            }

        }

        /**
         * Czyści zawartość canvas
         * @param {canvas} ctx 
         */
        clear(ctx) { ctx.clearRect(0, 0, 60, 60); }

        setTime(ctx, until) {
            ctx.font = this.font;
            ctx.shadowColor = "#000";
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 1;
            ctx.shadowBlur = 2;
            ctx.fillStyle = '#fff';
            ctx.textAlign = "center";
            ctx.fillText(until, this.cavnasXPosition, this.cavnasYPosition);
        }

        /**
         * 
         * @param {String} datepart  enum {'m',  'd', 'h', 's'}
         * @param {Date} fromdate 
         * @param {Date} todate 
         */
        dateDiff(datepart, fromdate, todate) {
            datepart = datepart.toLowerCase();
            let diff = todate - fromdate;
            let divideBy = {
                d: 86400000,
                h: 3600000,
                m: 60000,
                s: 1000,
                ms: 1
            };

            let modulo = {
                h: divideBy['d'],
                m: divideBy['h'],
                s: divideBy['m'],
                ms: divideBy['s']
            }

            return Math.floor((datepart === 'd' ? diff : diff % modulo[datepart]) / divideBy[datepart])
        }

    }