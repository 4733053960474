export class CountdownSettings {

    /**
     * Zwraca obiekt z najbiższą promocją
     * @returns {array} 
     * {
     *  imagePath: {string}'2019/03/14',
     *  link: {string} 'index.php?p=56565',
     *  date: {Date} 
     * }
     */
    getClosestsPromotion(settings) {

        return new Promise((resolve, reject) => {
            // sortowanie
            let dates = this.sortDates(settings);

            // zwraca najbliższą dzisiejszej datę z tablicy
            let today = new Date();
            let nextPromo = dates.filter((row) => new Date(Object.keys(row)) > today.getTime())[0];

            if (nextPromo) {
                let nextPromoDate = new Date(Object.keys(nextPromo))//new Date(2019,2,18,15,50)//new Date(Object.keys(nextPromo));
                let [d, m, y] = nextPromoDate.toLocaleDateString('pl-PL').split('.');
                resolve({
                    imagePath: `${y}/${m}/${d}`,
                    link: Object.values(nextPromo),
                    date: nextPromoDate
                });
            }else{
                reject('Brak kolejnej promocji');
            }
        });
    }

    sortDates(settings) {
        return settings.sort(function(a, b) {
            return new Date(Object.keys(a)).getTime() - new Date(Object.keys(b)).getTime();
        });
    }

    /**
     * Pobiera plik z ustawieniami do banerów
     * @param {string} filename Ścieżka do pliku
     * @returns {Promise} Obietnica
     */
    loadJSON(filename) {
        return new Promise((resolve, reject) => {

            let xhr = new XMLHttpRequest();
            xhr.overrideMimeType("application/json");
            xhr.open('GET', filename, true);
            xhr.addEventListener('load', () => resolve(xhr.responseText));
            xhr.addEventListener('error', () => reject(new Error(xhr.statusText)));
            xhr.onreadystatechange = function (aEvt) {
                if (xhr.readyState == 4) {
                    switch(xhr.status){
                        case 404:
                            reject( new Error(`Brak pliku ${filename}`) );
                            break;
                        case 200:
                            resolve(xhr.responseText);
                            break;
                        default:
                            reject(new Error(xhr.statusText));
                    }
                }
              };
            xhr.send(null);

        })
    }
}