export class MobileMenu {

    constructor() {
        this.btnClose = document.querySelector('#menu_btn_close');
        this.btnOpen = document.querySelector('#menu_btn_open');
        this.btnInfoOpen = document.querySelector('#menu_info_btn_open');
        this.btnInfoClose = document.querySelector('#menu_info_btn_close');
        this.overlay = document.querySelector('.overlay');
        this.overlay2 = document.querySelector('.overlay2');
        this.bodyClassList = document.querySelector('body').classList

        this.closeOverlayClass = 'close-overlay';
        this.openOverlayClass = 'open-overlay';
    }


    checkIfMenuIsOpen() {
        const overlays = [this.overlay.getAttribute('aria-hidden'), this.overlay2.getAttribute('aria-hidden')]
        return !overlays.every(z => z == 'true')
    }

    toggleNoScrollOnBody() {
        if (this.checkIfMenuIsOpen()) {
            if (!this.bodyClassList.contains('noscroll')) {
                this.bodyClassList.add('noscroll')
            }
        } else {
            this.bodyClassList.remove('noscroll')
        }
    }


    toggleLeftMenu() {
        let btnOpen = this.btnOpen.classList;
        btnOpen.toggle(this.openOverlayClass);
        btnOpen.toggle(this.closeOverlayClass);

        let btnClose = this.btnClose.classList;
        btnClose.toggle(this.closeOverlayClass);
        btnClose.toggle(this.openOverlayClass);
        if (this.overlay.getAttribute('aria-hidden') == 'false') {
            this.overlay.setAttribute('aria-hidden', true);
        } else {
            this.overlay.setAttribute('aria-hidden', false);
        }
        this.toggleNoScrollOnBody()
    }
    toggleRightMenu() {
            let btnInfoOpen = this.btnInfoOpen.classList;
            btnInfoOpen.toggle(this.openOverlayClass);
            btnInfoOpen.toggle(this.closeOverlayClass);

            let btnInfoClose = this.btnInfoClose.classList;
            btnInfoClose.toggle(this.closeOverlayClass);
            btnInfoClose.toggle(this.openOverlayClass);

            if (this.overlay2.getAttribute('aria-hidden') == 'false') {
                this.overlay2.setAttribute('aria-hidden', true);
            } else {
                this.overlay2.setAttribute('aria-hidden', false);
            }
            this.toggleNoScrollOnBody()
        }
        /**
         * Otwiera lewe menu i zamyka prawe, gdy otwarte
         */
    openLeftMenu() {
        this.toggleLeftMenu();
        if (this.overlay2.getAttribute('aria-hidden') == 'false') {
            this.toggleRightMenu();
        }

    }
    closeLeftMenu() {
        this.toggleLeftMenu();
    }
    openRightMenu() {
        this.toggleRightMenu();
        if (this.overlay.getAttribute('aria-hidden') == 'false') {
            this.toggleLeftMenu();
        }

    }
    closeRightMenu() {
        this.toggleRightMenu();
    }

}