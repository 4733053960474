import Wallop from 'Wallop';
import './common/mobile.menu';
import 'wallop/css/wallop.css';
import 'wallop/css/wallop--fade.css';
import './strona-glowna/manufacturers.css';
import './strona-glowna/countdown-baner/countdown-baner';
import './strona-glowna/wallop.css';


// BANERY NA STRONIE GŁÓWNEJ
const imageDisplayTime = 3000;
const slider = document.querySelector('.Wallop');
const wallop = new Wallop(slider);

if (wallop.allItemsArray.length > 1) {
    setInterval(function () {
        wallop.next();
    }, imageDisplayTime);
}


