import { Countdown } from './Countdown.js';
import { CountdownSettings } from './CountdownSettings';
import './countdown-baner.css';

let countdownSettings = new CountdownSettings();
const banerPath='js/countdown-baner';

const settingsPromise = countdownSettings.loadJSON(`${banerPath}/data.json`);

runPromo();

function runPromo(){

    settingsPromise.then(response => countdownSettings.getClosestsPromotion(JSON.parse(response)) )
    .then(response=>{
        let countdown = new Countdown( response.date);
        let countdownLink = document.querySelector(".countdown-link");
        countdownLink.href = response.link;
        // return countdownSettings.loadImage(`${banerPath}/${response.imagePath}`);
        const srcPath =  `${banerPath}/${response.imagePath}`;
        let countdownImage = document.querySelector(".countdown-image");

        let sources = countdownImage.querySelectorAll('source');
        sources[0].srcset=`${srcPath}.jpg`;
        sources[1].srcset=`${srcPath}m.jpg`;

        let countdownImg = countdownImage.querySelector('img');
        countdownImg.src = `${srcPath}.jpg`;
        // countdownImage.srcset = `${srcPath}m.jpg 300w, ${srcPath}.jpg 600w`;
        countdownImg.onload = function(){
            document.querySelector('.countdown-baner').style.display = "block";
        }
        countdown.onEnd(()=>{runPromo()})
    })
    .catch(error => {
        console.error(error);
        document.querySelector('.countdown-baner').style.display = "none";
    })
}



