import { MobileMenu } from '../common/mobile.menu.class';

let mobileMenu = new MobileMenu();
let footerBtnEl = document.querySelectorAll('#footer button');
let footerBtnElLength = footerBtnEl.length;

for (var i = 0; i < footerBtnElLength; i++) {
    footerBtnEl[i].addEventListener('click', function (e) {
        e.preventDefault();
        let state = this.getAttribute('id');

        switch (state) {
            case 'menu_btn_open':
                mobileMenu.openLeftMenu();
                break;
            case 'menu_btn_close':
                mobileMenu.closeLeftMenu();
                break;
            case 'menu_info_btn_open':
                mobileMenu.openRightMenu();
                break;
            case 'menu_info_btn_close':
                mobileMenu.closeRightMenu();
                break;

        }
    });
}

var a = document.querySelectorAll('.sub-category');
var mq = window.matchMedia("(max-width: 1215px)");
mq.addListener(sizeChange);
sizeChange(mq)

function listener(e) {
    var target = e.target;
    if (!target.matches('a')) {
        var classList = target.classList;
        var subCategoryStyle = target.querySelector('ul.sub-category').style;


        if (classList.contains('sub-category-opened')) {
            subCategoryStyle.display = 'none';
            classList.remove('sub-category-opened');
        } else {
            closeOtherSubmenu(subCategoryStyle);
            classList.add('sub-category-opened');
            subCategoryStyle.display = 'block';
        }
    }
}

function closeOtherSubmenu(subCategoryStyle) {
    a.forEach(function (element, index, array) {
        if (a[index].parentNode.classList.contains('sub-category-opened')) {
            a[index].parentNode.classList.remove('sub-category-opened');
            var subCategoryStyle = a[index].parentNode.querySelector('ul.sub-category').style;
            subCategoryStyle.display = 'none';
        }
    });
}

function sizeChange(mq) {
    if (mq.matches) {
        a.forEach(function (element, index, array) {
            a[index].parentNode.addEventListener('click', listener);
        });
    } else {
        a.forEach(function (element, index, array) {
            a[index].parentNode.removeEventListener('click', listener);
        });
    }
}